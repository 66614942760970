@import url(../assets/fonts/stylesheet.css);
/*надо чтобы текст не прилипал к ячейкам таблицы, однако contract приходит с бека, в его форматирование лезть не надо*/
.article__text:not(.article--contract) td {
  padding: 5px;
}
.article--contract p span {
  background-color: transparent;
}
.article--contract .P57 {
  background-color: transparent;
}
.article--contract .Table3,
.article--contract .Table5 {
  display: none;
}
.article__iframe-container {
  margin-top: 10vh;
}
@media (max-width: 1400px) {
  .article__iframe-container {
    margin-top: 2vh;
  }
}
@media (max-width: 767px) {
  .article__iframe-container {
    overflow: hidden;
    max-height: 67vh;
  }
}
.article__iframe-sub-container {
  position: relative;
  height: 75vh;
}
.article__iframe {
  position: absolute;
  border: none;
  width: 100%;
  height: 75vh;
}
@media (max-width: 1400px) {
  .article__iframe {
    transform: scale(0.6) translateX(-28vw) translateY(-40vh);
    width: 76vw;
    height: 130vh;
  }
}
@media (max-width: 767px) {
  .article__iframe {
    width: 160vw;
    transform: scale(0.6) translateX(-60vw) translateY(-40vh);
    height: 115vh;
    z-index: 20;
  }
}
.z-1 {
  z-index: 1;
}
